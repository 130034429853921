import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";
import { AgmCoreModule } from "@agm/core";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { TranslateModule } from "angular-intl";

import { NgbModule, NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";

import { AppComponent } from "./app.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { NavbarComponent } from "./partials/navbar/navbar.component";
import { SidebarComponent } from "./partials/sidebar/sidebar.component";
import { FooterComponent } from "./partials/footer/footer.component";
import { LoginComponent } from "./auth/login/login.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AlertModule } from "ngx-alerts";
import { ForgotPassComponent } from "./auth/forgot-pass/forgot-pass.component";
import { AccountComponent } from "./user/account/account.component";
import { DeleteComponent } from "./user/account/partials/delete/delete.component";
import { InfoComponent } from "./user/account/partials/info/info.component";
import { TokenInterceptor } from "./util/http.interceptor";
import { EventAddComponent } from "./event/event-add/event-add.component";
import { EventListComponent } from "./event/event-list/event-list.component";
import { RegisterComponent } from "./auth/register/register.component";
import { EventAdminsComponent } from "./event/event-admins/event-admins.component";
import { EventHomeComponent } from "./event/event-home/event-home.component";
import { EventCategoriesSubscriptionsComponent } from "./event/event-categories/event-categories-subscriptions/event-categories-subscriptions.component";
import { EventCategoriesComponent } from "./event/event-categories/event-categories.component";
import { EventInfoComponent } from "./event/event-info/event-info.component";
import { EventLiveComponent } from "./event/event-live/event-live.component";
import { CategoriesComponent } from "./event/event-live/categories/categories.component";
import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";
import { environment } from "src/environments/environment";
import { CategoryComponent } from "./event/event-live/category/category.component";
import { MenuComponent } from "./event/event-live/menu/menu.component";
import { EventGeneralComponent } from "./event/event-general/event-general.component";
import { AnimalAddComponent } from "./animal/animal-add/animal-add.component";
import { CompetitorAddComponent } from "./competitor/competitor-add/competitor-add.component";
import { EventEditComponent } from "./event/event-edit/event-edit.component";
import { EventDateEditComponent } from "./event/event-date-edit/event-date-edit.component";
import { LOCALE_ID } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localePt from "@angular/common/locales/pt";
import localePtExtra from "@angular/common/locales/extra/pt";
import { EventRankingComponent } from "./event/event-categories-ranking/event-categories-ranking.component";
import { EventAwardsComponent } from "./event/event-categories-awards/event-categories-awards.component";
import { EventCategoriesRulesComponent } from "./event/event-categories-rules/event-categories-rules.component";
import { FinancialComponent } from "./financial/financial.component";
import { FinancialUsersComponent } from "./financial/financial-users/financial-users.component";
import { FinancialUsersDetailComponent } from "./financial/financial-users/financial-users-detail/financial-users-detail.component";
import { NgbDateCustomParserFormatter } from "./util/NgbDateCustomParserFormatter";
import { FinancialUsersSubscriptionsComponent } from "./financial/financial-users/financial-users-detail/financial-users-subscriptions/financial-users-subscriptions.component";
import { FinancialUsersAwardsComponent } from "./financial/financial-users/financial-users-detail/financial-users-awards/financial-users-awards.component";
import { FinancialCompetitorsComponent } from "./financial/financial-competitors/financial-competitors.component";
import { FinancialCompetitorsDetailsComponent } from "./financial/financial-competitors/financial-competitors-details/financial-competitors-details.component";
import { FinancialCategoriesComponent } from "./financial/financial-categories/financial-categories.component";
import { EventResultComponent } from "./event/event-result/event-result.component";
import { FinancialAccountingsComponent } from "./financial/financial-accountings/financial-accountings.component";
import { FinancialAccountingsItemsComponent } from "./financial/financial-accountings/financial-accountings-items/financial-accountings-items.component";
import { EventSubscriptionlistComponent } from "./event/event-subscriptionlist/event-subscriptionlist/event-subscriptionlist.component";
import { LoadingComponent } from "./util/loading/loading.component";
import { TypeGroupHomeComponent } from "./typeGroup/typeGroup-home/typeGroup-home.component";
import { EventTypeGroupComponent } from "./event/event-typeGroup/event-typeGroup.component";
import { EventTypeGroupInfoComponent } from "./event/event-type-group-info/event-type-group-info.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { AnimalListComponent } from "./animal/animal-list/animal-list.component";
import { CompetitorListComponent } from "./competitor/competitor-list/competitor-list.component";
import { EventCategoriesSettingsComponent } from "./event/event-categories-settings/event-categories-settings.component";
import { EventStallsComponent } from "./event/event-stalls/event-stalls.component";
import { FinancialAnimalsComponent } from "./financial/financial-animals/financial-animals.component";
import { FinancialAnimalsDetailsComponent } from "./financial/financial-animals/financial-animals-details/financial-animals-details.component";
import { EventStallsDetailsComponent } from "./event/event-stalls/event-stalls-details/event-stalls-details.component";
import { NgxMaskModule } from "ngx-mask";
import { EventSubscriptionsComponent } from "./event/event-subscriptions/event-subscriptions.component";
import { SearcherComponent } from "./components/searcher/searcher.component";
import { EventPromotionsComponent } from "./event/event-promotions/event-promotions.component";
import { HippicalYearPipeCompetitor } from "./util/hippicalYearPipeCompetitor";
import { HippicalYearPipeAnimal } from "./util/hippicalYearPipeAnimal";
import { CustomerComponent } from "./customer/customer.component";
import { PassComponent } from "./event/event-live/category/pass/pass.component";
import { ActivityRegistryComponent } from "./activity-registry/activity-registry.component";
import { RegistryStatusPipe } from "./util/registryStatusPipe";
import { EventCategoriesAbqmComponent } from "./event/event-categories-abqm/event-categories-abqm.component";
import { EventCategoriesAbqmResultComponent } from "./event/event-categories-abqm-result/event-categories-abqm-result.component";
import { FinancialStallsComponent } from "./financial/financial-stalls/financial-stalls.component";
import { EventCategoriesRankComponent } from "./event/event-categories-rank/event-categories-rank.component";
import { CurrencyPipe } from "./util/currencyPipe";
import { TranslateCategoryPipe } from "./util/translateCategoryPipe";
import { SafeTranslatePipe } from "./util/safeTranslate";
import { EventSubscriptionsBetaComponent } from "./event/event-subscriptions-beta/event-subscriptions-beta.component";

registerLocaleData(localePt, "en", localePtExtra);

export let token = null;

if (localStorage.getItem("currentUser"))
  token = JSON.parse(localStorage.getItem("currentUser")).token;

export const config: SocketIoConfig = {
  url: environment.apiUrl,
  options: {
    query: { token },
  },
};

@NgModule({
  declarations: [
    AppComponent,

    DashboardComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    ForgotPassComponent,
    AccountComponent,
    DeleteComponent,
    InfoComponent,
    EventAddComponent,
    EventListComponent,
    RegisterComponent,
    EventAdminsComponent,
    EventHomeComponent,
    EventCategoriesSubscriptionsComponent,
    EventCategoriesComponent,
    EventInfoComponent,
    EventLiveComponent,
    CategoriesComponent,
    CategoryComponent,
    MenuComponent,
    EventGeneralComponent,
    AnimalAddComponent,
    CompetitorAddComponent,
    EventEditComponent,
    EventDateEditComponent,
    EventRankingComponent,
    EventAwardsComponent,
    EventCategoriesRulesComponent,
    FinancialComponent,
    FinancialUsersComponent,
    FinancialUsersDetailComponent,
    FinancialUsersSubscriptionsComponent,
    FinancialUsersAwardsComponent,
    FinancialCompetitorsComponent,
    FinancialCompetitorsDetailsComponent,
    FinancialCategoriesComponent,
    EventResultComponent,
    FinancialAccountingsComponent,
    FinancialAccountingsItemsComponent,
    EventSubscriptionlistComponent,
    LoadingComponent,
    EventTypeGroupComponent,
    TypeGroupHomeComponent,
    EventTypeGroupInfoComponent,
    AnimalListComponent,
    CompetitorListComponent,
    EventCategoriesSettingsComponent,
    EventStallsComponent,
    FinancialAnimalsComponent,
    FinancialAnimalsDetailsComponent,
    EventStallsDetailsComponent,
    EventSubscriptionsComponent,
    SearcherComponent,
    EventPromotionsComponent,
    HippicalYearPipeCompetitor,
    HippicalYearPipeAnimal,
    CurrencyPipe,
    CustomerComponent,
    PassComponent,
    ActivityRegistryComponent,
    RegistryStatusPipe,
    EventCategoriesAbqmComponent,
    EventCategoriesAbqmResultComponent,
    FinancialStallsComponent,
    EventCategoriesRankComponent,
    TranslateCategoryPipe,
    SafeTranslatePipe,
    EventSubscriptionsBetaComponent,
  ],
  imports: [
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBj3zvVpVOlwW6we0Eue8AL9DHVoigG_no",
      libraries: ["places"],
    }),
    AngularEditorModule,
    BrowserAnimationsModule,
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    CKEditorModule,
    AlertModule.forRoot({
      maxMessages: 5,
      timeout: 5000,
      positionX: "right",
      positionY: "top",
    }),
    NgbModule,
    SocketIoModule.forRoot(config),
    // ServiceWorkerModule.register("ngsw-worker.js", {
    //   enabled: environment.production,
    // }),
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({ path: "/assets/languages/" }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    EventCategoriesSubscriptionsComponent,
    EventEditComponent,
    EventDateEditComponent,
    FinancialUsersDetailComponent,
    FinancialCompetitorsDetailsComponent,
    FinancialAccountingsItemsComponent,
    EventSubscriptionlistComponent,
    EventTypeGroupInfoComponent,
  ],
})
export class AppModule {}
