import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertTranslateService } from 'src/app/services/alert.service';
import { EventService } from 'src/app/services/event.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-event-date-edit',
  templateUrl: './event-date-edit.component.html',
  styleUrls: ['./event-date-edit.component.scss'],
  providers: [DatePipe]
})
export class EventDateEditComponent implements OnInit {
  editEventDateForm: FormGroup;
  submitted: boolean = false;
  @Input() event: any;
  eventDaysCount: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertTranslateService,
    private _event: EventService,
    public activeModal: NgbActiveModal,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    // Converter timestamp para formato de data para o formulário
    const startDate = this.formatDateForInput(this.event.startDate);
    const finalDate = this.formatDateForInput(this.event.finalDate);
    
    // Calcular a quantidade atual de dias do evento
    this.eventDaysCount = this.event.eventDays ? this.event.eventDays.length : 1;

    this.editEventDateForm = this.formBuilder.group({
      startDate: [startDate, [Validators.required]],
      finalDate: [finalDate, [Validators.required]]
    }, { validator: (group) => this.dateRangeValidator(group) });
    
    // Adicionar listener para atualizar a data final automaticamente quando a data inicial mudar
    this.editEventDateForm.get('startDate').valueChanges.subscribe(value => {
      if (value) {
        const startDate = new Date(value);
        // Calcular a data final baseada na quantidade de dias do evento
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + this.eventDaysCount - 1);
        
        // Atualizar o campo de data final
        this.editEventDateForm.get('finalDate').setValue(
          this.datePipe.transform(endDate, 'yyyy-MM-dd')
        );
      }
    });
  }

  // Validador personalizado para garantir que a data final seja após a data inicial
  dateRangeValidator(group: FormGroup) {
    const startValue = group.get('startDate').value;
    const endValue = group.get('finalDate').value;
    
    if (!startValue || !endValue) {
      return null;
    }
    
    const start = new Date(startValue);
    start.setHours(0, 0, 0, 0);
    
    const end = new Date(endValue);
    end.setHours(0, 0, 0, 0);
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (start < today) {
      return { startDateInPast: true };
    }

    if (end < start) {
      return { endBeforeStart: true };
    }
    return null;
  }

  // Formatar timestamp para formato de data para o input
  private formatDateForInput(timestamp: number): string {
    // Converte o timestamp para uma data local
    const date = new Date(timestamp);
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  async onSubmit() {
    this.submitted = true;
    if (!this.editEventDateForm.valid) return;

    const { startDate, finalDate } = this.editEventDateForm.controls;

    try {
      // Converter datas para timestamp considerando o fuso horário local
      // O valor do input date é uma string no formato 'YYYY-MM-DD' sem informação de timezone
      // Precisamos criar uma data no fuso horário local do usuário
      
      // Extrair os componentes da data a partir da string
      const [startYear, startMonth, startDay] = startDate.value.split('-').map(Number);
      const [finalYear, finalMonth, finalDay] = finalDate.value.split('-').map(Number);
      
      // Criar datas usando os componentes (meses em JavaScript são 0-indexed)
      // Ajusta para meia-noite no fuso horário local para a data inicial
      const startTimestamp = new Date(
        startYear,
        startMonth - 1, // Meses em JavaScript são 0-indexed (janeiro = 0)
        startDay,
        0, 0, 0, 0
      ).getTime();
      
      // Ajusta para o final do dia (23:59:59.999) para a data final
      const finalTimestamp = new Date(
        finalYear,
        finalMonth - 1, // Meses em JavaScript são 0-indexed (janeiro = 0)
        finalDay,
        23, 59, 59, 999
      ).getTime();
      

      await this._event.updateEventDates(
        this.event._id, 
        startTimestamp, 
        finalTimestamp
      );
      
      this.alertService.success('EVENT-DATES-UPDATED');
      this.submitted = false;
      this.activeModal.close('updated');
    } catch (ex) {
      this.alertService.danger('ERROR-UPDATE-EVENT-DATES');
      this.submitted = false;
    }
  }

  get f() {
    return this.editEventDateForm.controls;
  }
}
