<div class="modal-header">
  <h4 class="modal-title" translate>EVENT.EDIT_DATES</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<form [formGroup]="editEventDateForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-warning">
          <p translate>EVENT.DATES_WARNING</p>
          <p><strong translate>EVENT.DAYS_COUNT</strong>: {{ eventDaysCount }}</p>
          <p translate>EVENT.DAYS_COUNT_INFO</p>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="startDate" translate>START_DATE</label>
          <input
            type="date"
            formControlName="startDate"
            id="startDate"
            class="form-control"
          />
          <div *ngIf="submitted && f.startDate.invalid" class="alert alert-danger">
            <small
              *ngIf="f.startDate.errors?.required"
              class="form-text text-danger"
              translate
              >START_DATE_NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="finalDate" translate>FINAL_DATE</label>
          <input
            type="date"
            formControlName="finalDate"
            id="finalDate"
            class="form-control"
          />
          <div *ngIf="submitted && f.finalDate.invalid" class="alert alert-danger">
            <small
              *ngIf="f.finalDate.errors?.required"
              class="form-text text-danger"
              translate
              >FINAL_DATE_NEEDED</small
            >
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="submitted && editEventDateForm.errors">
        <div class="alert alert-danger">
          <small *ngIf="editEventDateForm.errors?.endBeforeStart" translate>
            FINAL_DATE_BEFORE_START_DATE
          </small>
          <small *ngIf="editEventDateForm.errors?.startDateInPast" translate>
            START_DATE_IN_PAST
          </small>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="activeModal.close('Close click')"
      translate
    >
      CLOSE
    </button>
    <button
      type="submit"
      class="btn btn-sm-block btn-gradient-primary mr-2 float-right"
      translate
    >
      SAVE
    </button>
  </div>
</form>
